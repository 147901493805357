import { useRouter } from 'next/router';
import { useEffect } from 'react';

const message = `



        🌞))))    🌞)))🌞))))))  🌞))))     🌞))         🌞))    🌞))))
      🌞))    🌞))     🌞))    🌞))    🌞))  🌞))       🌞))  🌞))     🌞))
    🌞))        🌞))   🌞))  🌞))        🌞)) 🌞))     🌞)) 🌞))         🌞))
    🌞))        🌞))   🌞))  🌞))        🌞))  🌞))   🌞))  🌞))         🌞))
    🌞))        🌞))   🌞))  🌞))        🌞))   🌞)) 🌞))   🌞))         🌞))
      🌞))     🌞))    🌞))    🌞))     🌞))     🌞))))      🌞))      🌞))
        🌞))))         🌞))      🌞))))           🌞))          🌞)))))



                        🌄 Join the solar revolution 🌅
                              💪 We're hiring 💪
                          https://careers.otovo.com/



`;

const WeAreHiring = () => {
  const { query, isReady } = useRouter();
  const { context } = query;

  useEffect(() => {
    if (isReady && context !== 'iframe') {
      if (!window.matchMedia || typeof console === 'undefined') {
        return;
      }
      const darkMode = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;

      const color = darkMode ? '#FE8F65' : 'black';
      const styles = `color: ${color}`;
      // eslint-disable-next-line no-console
      console.log(`%c${message}`, styles);
    }
  }, [isReady, context]);

  return null;
};

export default WeAreHiring;
